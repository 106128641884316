import React, {useEffect, useState} from 'react';
import './Benjamin.css';

import cat from '../img/cat.svg';

import NavBar from "../components/NavBar";
import StarsBackground from "../components/StarsBackground";
import Socialsbar from "../components/Socialsbar";
import github_logo from "../img/github-logo.svg";

function App() {

    function id(id) {
        return document.getElementById(id)
    }

    const [things, setThings] = useState([
        { title: "Leaner Startups", position: "Web Developer" , description: "MERN Stack developer"},
        { title: "WordplayPen", position: "Developer" ,description: "New programming language" },
        { title: "SEAL", position: "Web Developer" , description: "Frontend (HTML, JS, CSS, Bootstrap, WordPress)" },
        { title: "Tribble Lab", position: "Web Developer" , description: "Frontend (HTML, JS, CSS, Bootstrap, WordPress)" },
        { title: "Matsu Lab", position: "Web Developer" , description: "Frontend (HTML, JS, CSS, Bootstrap, WordPress)" }
    ]);
    const [projects, setProjects] = useState([
        { title: "Eye tracker", description: "Eye tracker", link: "https://github.com/SEAL-Dev/SEAL", skills: ['Java', 'Python', 'JavaScript', 'C++', 'TypeScript', 'SQL', 'HTML', 'CSS']},
        { title: "E-commerce simulation", description: "asdasdaasdasd  asd asdasads saasd", link: "https://github.com/SEAL-Dev/SEAL"},
        { title: "Human Bench Mark", description: "Fasdasdasasas dadsasdadsasd asasdasd  ", link: "https://github.com/SEAL-Dev/SEAL" }
    ]);

    useEffect(() => {

    }, []);
    return (
        <div id='ben-page'>
            <NavBar></NavBar>
            <StarsBackground></StarsBackground>
            <div id='ben-body'>
                <div id='ben-top-left-right'>
                    <div id='ben-intro-texts'>
                        <h1 id='ben-name'>Benjamin So</h1>
                        <h2>My current experiences are too generic to have some quirky description about me </h2>
                        <div>
                            <h2>A little about me</h2>
                            <p>Cat owner of 4</p>
                            <p>homecook</p>
                            <p>occasional sports enjoyeer</p>
                        </div>
                        <Socialsbar github={'https://github.com/BenjaminSoYH'}
                                    linkedin={'https://github.com/BenjaminSoYH'}></Socialsbar>
                    </div>
                    <img id='ben-avatar' src={cat}/>
                </div>
                <div id='ben-part-of'>
                    <h1 className='ben-section-title'>Things I've been part of</h1>
                    <div id='ben-team-container'>
                        {things.map((thing, index) => (
                            <div key={index} className="ben-team">
                                <h2>{thing.title}</h2>
                                <h4>{thing.description}</h4>
                            </div>
                        ))}
                    </div>
                </div>
                <div id='ben-projects'>
                    <h1 className='ben-section-title'>Reccent Projects</h1>
                    <div id='ben-team-container'>
                        {projects.map((thing, index) => (
                            <div key={index} className="ben-team">
                                <h2>{thing.title}</h2>
                                <h4>{thing.description}</h4>
                                <div>

                                    
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default App;